<template>
  <v-container fluid>
    <v-layout column v-show="!showMail">
      <v-layout class="d-flex justify-center align-center" v-if="loading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-layout>
      <v-list three-line v-else>
        <v-list-item-group v-model="selecteMail" active-class="primary--text">
          <template v-for="(item, index) in tableData">
            <v-list-item :key="item.id"  @click="showChiTiet(item)">
              <v-list-item-avatar color="indigo">
                <img
                  v-if="item.nguoi_gui && item.nguoi_gui.url_image"
                  :src="imageEndpoint + item.nguoi_gui.url_image"
                  alt="ManhLe"
                />
                <span style="color: white" v-else-if="item.nguoi_gui && item.nguoi_gui.name">{{
                  item.nguoi_gui.name.charAt(0).toUpperCase()
                }}</span>
                <v-icon v-else dark>mdi-account</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <div style="font-size: 18px; font-weight: 400">
                    {{ item.chu_de }}
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div v-if="item.nguoi_nhans.length < 4">
                    Đến:
                    <span
                      class="text--primary pl-1"
                      v-for="nguoiNhan in item.nguoi_nhans"
                      :key="nguoiNhan.id"
                    >
                      {{ nguoiNhan.name }},
                    </span>
                  </div>
                  <div v-else>
                    Đến:
                    <span class="text--primary pl-1"
                      >{{ item.nguoi_nhans[0].name }}
                    </span>
                    <span class="text--primary pl-1"
                      >{{ item.nguoi_nhans[1].name }}
                    </span>
                    <span class="text--primary pl-1"
                      >{{ item.nguoi_nhans[2].name }}
                    </span>
                    <span class="text--primary pl-1"
                      >Và {{ item.nguoi_nhans.length - 3 }} người khác
                    </span>
                  </div>
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle>
                  {{
                    item.noi_dung.length > 90
                      ? String(item.noi_dung).substr(0, 90) + "..."
                      : item.noi_dung
                  }}
                </v-list-item-subtitle> -->
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text
                  v-text="item.thoi_gian"
                ></v-list-item-action-text>

                <v-chip class="mt-2" color="red" text-color="white" small>
                  {{ item.so_binh_luan }}
                  Bình luận
                </v-chip>

                <!-- <v-icon v-if="!active" color="grey lighten-1">
              mdi-star-outline
            </v-icon> -->

                <!-- <v-icon color="yellow darken-3"> mdi-star </v-icon> -->
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="item.id + '-' + index" :inset="true"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
      <div class="pt-2">
        <v-pagination v-model="page" :length="pageCount" @input="changePage"
          >></v-pagination
        >
      </div>
    </v-layout>

    <v-layout v-show="showMail">
      <single-mail :mailId="mailId" @back-to-inbox="showMail = false"></single-mail>
    </v-layout>
  </v-container>
</template>
<script>
import { getThuCaNhan } from "@/api/guithu";
import SingleMail from "./singleMail";
export default {
  components: { SingleMail },
  data: () => ({
    imageEndpoint: process.env.VUE_APP_BASE,
    items: [
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle:
          '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
    ],
    showMail: false,
    mailId: null,
    dialog: false,
    loading: false,
    selecteMail: undefined,
    page: 1,
    pageCount: 1,
    itemsPerPage: 5,
    tableData: [],
    search: "",
  }),
  mounted() {
    this.getThuGui();
  },
  computed: {
    USER() {
      return this.$store.state.User.me;
    },
  },
  methods: {
    async getThuGui() {
      this.loading = true;
      let data = await getThuCaNhan({
        page: this.page,
        perPage: this.itemsPerPage,
        search: this.search,
      });
      this.loading = false;
      this.tableData = data.data;
      this.pageCount = data.meta.last_page;
    },
    changePage(val) {
      this.page = val;
      this.getThuGui();
    },
    showChiTiet(item){
      this.mailId = Number(item.id)
      if(this.mailId){
        this.showMail = true
      }else{
        this.showMail = false
      }
    }
  },
};
</script>
<style scoped>
</style>
