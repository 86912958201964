<template>
  <v-container fluid>
    <div style="font-size: 20px; color: black">Danh sách nhóm truyền thông</div>
    <v-list three-line>
      <v-list-item-group v-model="selected" active-class="primary--text">
        <template v-for="(item, index) in tableData">
          <v-list-item :key="item.id" @click="showChiTiet(item)">
            <v-list-item-avatar color="indigo">
              <span style="color: white">{{
                item.name.charAt(0).toUpperCase()
              }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <div style="font-size: 18px; font-weight: 400">
                  {{ item.name }}
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{
                  item.mo_ta > 90
                    ? String(item.mo_ta).substr(0, 90) + "..."
                    : item.mo_ta
                }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Người tạo:
                {{ item.nguoi_tao.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <!-- <v-btn icon color="green" @click="editGroup(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->
              <v-chip class="mt-2" color="red" text-color="white" small>
                {{ item.so_thanh_vien }} Thành viên
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="item.id + '-' + index" :inset="true"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
    <div class="pt-2 pb-3">
      <v-pagination v-model="page" :length="pageCount" @input="changePage"
        >></v-pagination
      >
    </div>
  </v-container>
</template>

<script>
import { getNhom } from "@/api/nhom";

export default {
  data: () => ({
    imageEndpoint: process.env.VUE_APP_BASE,
    date: [],
    selected: null,
    showDate: false,
    dateRangeText: "",
    page: 1,
    pageCount: 1,
    itemsPerPage: 5,
    tableData: [],
    loading: false,
    nhomId: null,
    groupName: null,
  }),

  mounted() {
    this.getData();
    this.getChiTiet();
  },
  methods: {
    async getData() {
      this.loading = true;
      let data = await getNhom({
        page: this.page,
        perPage: this.itemsPerPage,
        search: this.search,
      });
      this.loading = false;
      this.tableData = data.data;
      this.pageCount = data.meta.last_page;
    },
    async getChiTiet() {
      if (Number.isInteger(Number(this.$route.params.id))) {
        this.nhomId = Number(this.$route.params.id);
      } else return;
    },
    addGroup() {
      this.$refs.group.showFormAdd();
    },
    editGroup(data) {
      this.$refs.group.showFormEdit(data);
    },
    formatDate(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },
    changePage(val) {
      this.page = val;
      this.getData();
    },
    showChiTiet(item) {
    //   if (item.id != this.$route.params.id) {
    //     this.$router.push({ params: { id: item.id } });
    //   }
    //   this.nhomId = item.id;
    //   this.groupName = item.name;
    this.$router.push('/truyenthong/nhom/' + item.id)
    },
  },
};
</script>

<style>
</style>